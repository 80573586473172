import React from "react";
import classnames from "classnames";
import styles from "./Text.module.scss";

type Props = {
  children?: React.ReactNode;
  className?: string;
  isCenter?: boolean;
  isHeading?: boolean;
  isSubheading?: boolean;
  mb?: boolean; // margin-bottom
  style?: object;
  text?: string | number;
  id?: string;
  "data-testid"?: string;
};

export const Text: React.FC<Props> = (props) => {
  const {
    children,
    className,
    isCenter,
    isHeading,
    isSubheading,
    mb,
    style,
    text,
    id,
    "data-testid": dataTestId,
  } = props;

  const cls = classnames(className, styles.text, {
    [styles.textCenter]: isCenter,
    [styles.textHeading]: isHeading,
    [styles.textSubeading]: isSubheading,
    [styles.marginBottom]: mb,
  });

  if (isHeading) {
    return (
      <h1 className={cls} style={style} id={id} data-testid={dataTestId}>
        {text || children}
      </h1>
    );
  }

  if (isSubheading) {
    return (
      <h2 className={cls} style={style} id={id} data-testid={dataTestId}>
        {text || children}
      </h2>
    );
  }

  return (
    <p className={cls} style={style} id={id} data-testid={dataTestId}>
      {text || children}
    </p>
  );
};
